// import * as blueColor from "../style/colors/blue.css";
// import * as redColor from "../style/colors/red.css";
// import * as yellowcolor from "../style/colors/yellow.css";
// import * as greenColor from "../style/colors/green.css";
// const themeColors = {
//   blue: blueColor,
//   red: redColor,
//   yellow: yellowcolor,
//   green: greenColor,
// };
const constants = {
  mode: "dark", // this will alter between day (white)  and night(black)
  themeColor: "red", // can have variour options
  // themeColors: themeColors,
};

export default constants;
